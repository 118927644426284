/*
	Textured background taken from
	https://css-tricks.com/heres-how-i-recreated-theory11s-login-form-how-would-you-do-it/
*/
:root {
	--gray: rgb(14, 19, 25);
	--gray--t: rgba(14, 19, 25, 0);
}

@media screen and (min-width: 740px) {
	.NoContextPage {
		background: rgb(14, 19, 25);
		background:
			radial-gradient(circle at top left, #ffffff 0%, #ffffff 49%, transparent 49%),
			linear-gradient(var(--gray) 30%, var(--gray--t)),
			var(--gray)
				url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="28" height="49" viewBox="0 0 28 49"%3E%3Cg fill-rule="evenodd"%3E%3Cg id="hexagons" fill="%239C92AC" fill-opacity="0.1" fill-rule="nonzero"%3E%3Cpath d="M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
	}

	.NoContextPage__logo {
		position: relative;
		height: 100vh;
		overflow: hidden;
	}

	.NoContextPage__logo svg {
		width: 50%;
		position: relative;
		padding: 10%;
		max-height: 60%;
		box-sizing: content-box;
	}

	.NoContextPage__content {
		min-height: 100vh;
	}
}

@media screen and (max-width: 740px) {
	.NoContextPage {
		display: block !important;
		background: rgb(14, 19, 25);
		background:
			linear-gradient(var(--gray) 30%, var(--gray--t)),
			var(--gray)
				url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="28" height="49" viewBox="0 0 28 49"%3E%3Cg fill-rule="evenodd"%3E%3Cg id="hexagons" fill="%239C92AC" fill-opacity="0.1" fill-rule="nonzero"%3E%3Cpath d="M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z"/%3E%3C/g%3E%3C/g%3E%3C/svg%3E');
		min-height: 100vh;
	}

	.NoContextPage__logo {
		text-align: center;
	}

	.NoContextPage__logo svg {
		max-width: 20%;
		margin: 2% 0;
		padding: 2%;
		background: white;
		border-radius: 2%;
	}

	.NoContextPage__content {
		padding: 10px;
	}
}
