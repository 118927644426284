.LinearLoader-container {
	position: relative;
	background: transparent;
	transition: 0.75s;
}

.LinearLoader-container__loading {
	background: rgba(0, 0, 0, 0.15);
}

.LinearLoader-overlay {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: 1s;
	display: flex;
	align-items: flex-end;
}

.LinearLoader-overlay > div {
	flex: 1;
}
